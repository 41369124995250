:root {
  /* These are used in the root for updating widgets to match clients custom colors */
  --brand-color: #007dba;
  --brand-secondary: #444444;
  --modal-bg-img: url("assets/image/laz_white_bg.png");
}


@import "../node_modules/bootstrap/scss/bootstrap";
@import "./button-overrides.scss";

* {
  box-sizing: border-box;
}

html, body, #app {
  height: 100% !important;
}

body {
  font-family: "Roboto", Arial, Verdana, Sans-serif;
  font-display: swap;
  color: #444444;
  height: 100% !important;
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  monospace;
}

.footer-fixed {
  position: fixed;
  bottom: 0;
  left: 0;
  width: 100vw;
}


.footer-inner {
  padding: 25px;
  background-color: #fff;
  height: initial !important;
}

@media (min-width: 1200px) {
  .footer-fixed {
    position: fixed;
    bottom: 0;
    left: 256px;
    width: calc(100% - 256px);
  }
  .footer-inner {
    padding: 25px;
    background-color: #fff;
    position: relative !important;
    height: initial !important;
  }
}

/* SVG Icons */

.svg-icon {
  width: 18px;
  min-width: 18px;
  max-width: 18px;
  height: 18px;
  cursor: pointer;
}

.svg-icon-15 {
  width: 15px;
  min-width: 15px;
  max-width: 15px;
  height: 15px;
}

.svg-icon-12 {
  width: 12px;
  min-width: 12px;
  max-width: 12px;
  height: 12px;
}

.svg-icon-20 {
  width: 20px;
  min-width: 20px;
  max-width: 20px;
  height: 20px;
}

.svg-icon-25 {
  width: 25px;
  min-width: 25px;
  max-width: 25px;
  height: 25px;
}

.svg-icon-30 {
  width: 30px;
  min-width: 30px;
  max-width: 30px;
  height: 30px;
}

.svg-icon-sm {
  width: 27px;
  min-width: 27px;
  max-width: 27px;
  height: 27px;
  cursor: pointer;
}

.svg-blue {
  filter: invert(31%) sepia(25%) saturate(5246%) hue-rotate(177deg) brightness(98%) contrast(101%);
}

.svg-grey {
  filter: invert(58%) sepia(0%) saturate(0%) hue-rotate(196deg) brightness(93%) contrast(89%);
}

.svg-light-grey {
  filter: invert(76%) sepia(5%) saturate(80%) hue-rotate(182deg) brightness(99%) contrast(93%);
}

.svg-white {
  filter: invert(100%) sepia(0%) saturate(7467%) hue-rotate(98deg) brightness(98%) contrast(106%);
}

.svg-black {
  filter: invert(22%) sepia(1%) saturate(0%) hue-rotate(210deg) brightness(99%) contrast(84%);
}

.svg-red {
  filter: invert(55%) sepia(95%) saturate(7167%) hue-rotate(347deg) brightness(86%) contrast(94%);
}

.svg-history {
  margin-top: -3px;
  width: 22px;
  height: 22px;
  margin-right: 2px;
}

.mr-2 {
  margin-right: 0.5rem !important;
}

.mr-3 {
  margin-right: 1rem !important;
}

#app {
  height: 100%;
}

.text-overflow-ellipses {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
}

.footer-single-btn {
  width: 50%;
  float: right !important;
}

.fade-enter {
  opacity: 0 !important;
  transform: translate(0, 25px);
  z-index: 1;
}

.fade-enter.fade-enter-active {
  opacity: 1 !important;
  transform: translate(0, 0);

  transition: opacity 350ms ease-out, transform 350ms ease;
}

.fade-exit {
  opacity: 1 !important;
  transform: translate(0, 0);
}

.fade-exit.fade-exit-active {
  opacity: 0 !important;
  transform: translate(0, 30px);

  transition: opacity 200ms ease-out, transform 350ms ease;
}

.focus-event {
  &:focus {
    outline: 3px solid #007dba;
    outline-offset: 3px;
    border-radius: 2px;
  }
}

.text-dark-l3 {
  color: #6a6d75 !important;
}

.text-secondary-mid {
  color: #888;
}

.cursor-pointer {
  cursor: pointer !important;
}

.nav-text {
  color: #000;
}

.radius-round {
  border-radius: 4rem !important;
}

.w-100 {
  width: 100% !important;
}

.search-cntr {
  padding: 15px;
}

.search-cntr .search-cntr-nav-item-horz-tab {
  background-color: transparent !important;
  color: #fff !important;
  min-height: 40px;
  padding-top: 0px;
}

.search-cntr .search-cntr-nav-item-horz-tab-border {
  background-color: #b0b9d4;
}

@media (min-width: 768px) {
  .search-cntr .search-cntr-nav-item-horz-tab-border {
    background-color: #e4e4e4;
  }
}

.search-cntr
.search-cntr-nav-item-horz-tab.active
.search-cntr-nav-item-horz-tab-border {
  background-color: #fff;
}

.search-cntr .search-cntr-nav-item-horz-tab.active {
  font-weight: bold;
}

a.search-cntr-nav-item-horz-tab:hover {
  color: #fff !important;
}

.search-cntr.nav-tabs {
  width: 85% !important;
  margin: 0 auto;
  margin-bottom: 20px !important;
}

.nav-item-horz-tab {
  min-height: 58px;
  font-size: 19px;
  padding: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff !important;
  border: 0px !important;
  position: relative;
  color: #bcbdbf !important;
}

.nav-item-horz-tab.active {
  color: #5d6069 !important;
}

.nav-item-horz-tab-border {
  width: 100%;
  height: 1px;
  background-color: #dddedf;
  position: absolute;
  bottom: 0;
}

.nav-item-horz-tab.active .nav-item-horz-tab-border {
  height: 3px;
  background-color: #5d6069;
}

.text-right {
  text-align: right !important;
}

.text-left {
  text-align: left !important;
}

.text-grey-l1 {
  color: #949699 !important;
}

@media (min-width: 1200px) {
  .hide-1200 {
    display: none;
  }
  .show-1200 {
    display: block;
  }
}

@media (max-width: 1199px) {
  .hide-1200 {
    display: block;
  }
  .show-1200 {
    display: none;
  }
}

/* Digital Wallet */

.googleLoadingReplacement,
.appleLoadingReplacement {
  background-color: #000;
  height: 48px;
  border-radius: 50px;
  width: 100%;
  position: relative;
}

.digitalWalletLoader {
  position: absolute;
  top: 50%;
  left: 50%;
  color: white;
  transform: translate(-50%, -50%);
}

/* Apple Pay */

@supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: buy;
    width: 100%;
    height: 46px;
    border-radius: 3px;
  }
  .apple-pay-button-black {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line {
    -apple-pay-button-style: white-outline;
  }
  .apple-pay-button-with-text {
    display: inline-block;
    -webkit-appearance: -apple-pay-button;
    -apple-pay-button-type: buy;
  }
  .apple-pay-button-with-text > * {
    display: none;
  }
  .apple-pay-button-black-with-text {
    -apple-pay-button-style: black;
  }
  .apple-pay-button-white-with-text {
    -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line-with-text {
    -apple-pay-button-style: white-outline;
  }
}

@supports not (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
    display: inline-block;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 50% 50%;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  .apple-pay-button-black {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-white {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-white-with-line {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
    border: 0.5px solid black;
  }
  .apple-pay-button-with-text {
    --apple-pay-scale: 1; /* (height / 32) */
    display: inline-flex;
    justify-content: center;
    font-size: 12px;
    border-radius: 5px;
    padding: 0px;
    box-sizing: border-box;
    min-width: 200px;
    min-height: 32px;
    max-height: 64px;
  }
  .apple-pay-button-black-with-text {
    background-color: black;
    color: white;
  }
  .apple-pay-button-white-with-text {
    background-color: white;
    color: black;
  }
  .apple-pay-button-white-with-line-with-text {
    background-color: white;
    color: black;
    border: 0.5px solid black;
  }
  .apple-pay-button-with-text.apple-pay-button-black-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-white);
    background-color: black;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-text > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-with-text.apple-pay-button-white-with-line-with-text
  > .logo {
    background-image: -webkit-named-image(apple-pay-logo-black);
    background-color: white;
  }
  .apple-pay-button-with-text > .text {
    font-family: -apple-system;
    font-size: calc(1em * var(--apple-pay-scale));
    font-weight: 300;
    align-self: center;
    margin-right: calc(2px * var(--apple-pay-scale));
  }
  .apple-pay-button-with-text > .logo {
    width: calc(35px * var(--scale));
    height: 100%;
    background-size: 100% 60%;
    background-repeat: no-repeat;
    background-position: 0 50%;
    margin-left: calc(2px * var(--apple-pay-scale));
    border: none;
  }
}

.apple-pay-set-up-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: set-up;
}

.apple-pay-set-up-button-black {
  -apple-pay-button-style: black;
}

.apple-pay-set-up-button-white {
  -apple-pay-button-style: white;
}

.apple-pay-setup-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

.apple-pay-donate-button {
  display: inline-block;
  -webkit-appearance: -apple-pay-button;
  -apple-pay-button-type: donate;
}

.apple-pay-donate-button-black {
  -apple-pay-button-style: black;
}

.apple-pay-donate-button-white {
  -apple-pay-button-style: white;
}

.apple-pay-donate-button-white-with-line {
  -apple-pay-button-style: white-outline;
}

/* @supports (-webkit-appearance: -apple-pay-button) {
  .apple-pay-button {
      display: inline-block;
      -webkit-appearance: -apple-pay-button;
  }
  .apple-pay-button-black {
      -apple-pay-button-style: black;
  }
  .apple-pay-button-white {
      -apple-pay-button-style: white;
  }
  .apple-pay-button-white-with-line {
      -apple-pay-button-style: white-outline;
  }
} */

.d-none {
  display: none !important;
}


.p-25 {
  padding: 25px !important;
}

.pl-25 {
  padding-left: 25px !important;
}

.pr-25 {
  padding-right: 25px !important;
}

.pt-25 {
  padding-top: 25px !important;
}

.pb-25 {
  padding-bottom: 25px !important;
}

.ml-25 {
  margin-left: 25px !important;
}

.mr-25 {
  margin-right: 25px !important;
}

.mt-25 {
  margin-top: 25px !important;
}

.mt-n25 {
  margin-top: -25px !important;
}

.mb-10 {
  margin-bottom: 10px;
}

.mb-25 {
  margin-bottom: 25px !important;
}

.pl-1 {
  padding-left: 0.25rem !important;
}

.pl-2 {
  padding-left: 0.5rem !important;
}

.pl-3 {
  padding-left: 1rem !important;
}

.pl-4 {
  padding-left: 1.5rem !important;
}

.pl-5 {
  padding-left: 3rem !important;
}

.pr-1 {
  padding-right: 0.25rem !important;
}

.pr-2 {
  padding-right: 0.5rem !important;
}

.pr-3 {
  padding-right: 1rem !important;
}

.pr-4 {
  padding-right: 1.5rem !important;
}

.pr-5 {
  padding-right: 3rem !important;
}

.pt-1 {
  padding-top: 0.25rem !important;
}

.pt-2 {
  padding-top: 0.5rem !important;
}

.pt-3 {
  padding-top: 1rem !important;
}

.pt-4 {
  padding-top: 1.5rem !important;
}

.pt-5 {
  padding-top: 3rem !important;
}

.pb-1 {
  padding-bottom: 0.25rem !important;
}

.pb-2 {
  padding-bottom: 0.5rem !important;
}

.pb-3 {
  padding-bottom: 1rem !important;
}

.pb-4 {
  padding-bottom: 1.5rem !important;
}

.pb-5 {
  padding-bottom: 3rem !important;
}

.ml-1 {
  margin-left: 0.25rem !important;
}

.ml-2 {
  margin-left: 0.5rem !important;
}

.ml-3 {
  margin-left: 1rem !important;
}

.ml-4 {
  margin-left: 1.5rem !important;
}

.ml-5 {
  margin-left: 3rem !important;
}

.mr-1 {
  margin-right: 0.25rem !important;
}

.mr-4 {
  margin-right: 1.5rem !important;
}

.mr-5 {
  margin-right: 3rem !important;
}

.mt-1 {
  margin-top: 0.25rem !important;
}

.mt-2 {
  margin-top: 0.5rem !important;
}

.mt-3 {
  margin-top: 1rem !important;
}

.mt-4 {
  margin-top: 1.5rem !important;
}

.mt-5 {
  margin-top: 3rem !important;
}

.mb-1 {
  margin-bottom: 0.25rem !important;
}

.mb-2 {
  margin-bottom: 0.5rem !important;
}

.mb-3 {
  margin-bottom: 1rem !important;
}

.mb-4 {
  margin-bottom: 1.5rem !important;
}

.mb-5 {
  margin-bottom: 3rem !important;
}

.mt-45,
.my-45 {
  margin-top: 2rem !important;
}

.pl-35,
.px-35 {
  padding-left: 1.25rem !important;
}

.pr-35,
.px-35 {
  padding-right: 1.25rem !important;
}

.or-title {
  text-align: center;
  border-bottom: 1px solid #000;
  line-height: 0.1em;
  margin-top: 20px;
  margin-bottom: 40px;
}

.or-title span {
  background: #fff;
  padding: 0 10px;
}
